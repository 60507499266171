import React from "react";
import { navigate } from 'gatsby';
import axios from 'axios'
import styled from "styled-components";
import { StaticImage } from 'gatsby-plugin-image';

import { SectionTitle, Checkbox, HelpText } from "../Section";
import PrimaryLinkButton from "../Button/PrimaryLinkButton";
import LineArrowRight from "../Icons/LineArrowRight"
import MapPointerIcon from "../Icons/MapPointerIcon";
import PhoneIcon from "../Icons/PhoneIcon";
import TollFreeIcon from "../Icons/TollFreeIcon";
import MailIcon from "../Icons/MailIcon";

const Wrap = styled.div`
	position: relative;
	padding: 0;
	margin-bottom: -180px;
	background: white;
	.container {
		position: relative;
		display: flex;
		align-items: center;
	}

	@media(max-width: 992px) {
		.container {
			display: block;
			width: 100%;
		}
	}
`
const FormWrap = styled.div`
	width: 75%;
	padding: 57px 52px;
	background: #F7F5F5;
	border-radius: 6px;

	form {
		width: 65%;

		p {
			margin-bottom: 45px;
		}

		.two-col {
			display: flex;
			align-items: center;
			justify-content: space-between;

			input {
				width: 48%;
				padding: 16px 20px 19px;
				border: 1px solid #D0D0D0;
				border-radius: 6px;
				margin-bottom: 16px;
				box-shadow: 0 3px 12px rgba(0, 0, 0, .1);
			}
		}
		textarea {
			width: 100%;
			padding: 16px 20px 19px;
			border: 1px solid #D0D0D0;
			border-radius: 6px;
			margin-bottom: 16px;
			box-shadow: 0 3px 12px rgba(0, 0, 0, .1);
			resize: none;
		}
	}
	@media(max-width: 992px) {
		width: 100%;

		form {
			width: 100%;
		}
	}
	@media(max-width: 600px) {
		padding: 30px 15px;

		form {
			width: 100%;
			.two-col {
				flex-wrap: wrap;
				input {
					width: 100%;
				}
			}
		}
	}
`

const AddressWrap = styled.div`
	width: 50%;
	padding: 20px 60px 40px;
	margin-left: -25%;
	background: #E43D3C;
	border-radius: 6px;
	.logo {
		text-align: center;
	}
	.divided-border {
		width: 200px;
		height: 1px;
		border: 0;
		border-bottom: 1px solid #ffffff;
		margin: 10px 0;
	}
	.city-name {
		font-size: 18px;
		font-weight: 600;
		color: #ffffff;
		margin: 15px 0 0;
	}
	.item {
		display: flex;
		align-items: flex-start;
		margin-top: 5px;
		color: #ffffff;
		font-size: 16px;
		line-height: 30px;

		a {
			color: #ffffff;
		}

		svg {
			margin-top: 5px;
			margin-right: 20px;
		}
	}
	@media(max-width: 992px) {
		width: 100%;
		margin-left: 0;
	}
	@media(max-width: 600px) {
		padding: 30px 20px;
		.item {
			font-size: 14px;
			line-height: 24px;

		}
	}
`

class GetInTouch extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      full_name: '',
      email: '',
      phone: '',
      zipcode: '',
      comment: '',
	  agreeViaSms: true,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const {id, value} = event.target
	if (id !== "agreeViaSms") {
	this.setState({
		[id]: value,
	})
	} else {
	this.setState({
		[id]: event.target.checked,
	})
	}
  }
  handleSubmit(event) {
    event.preventDefault();
		const { location } = this.props;
    const data = {
			source: location.origin,
			form: "Get In Touch Form",
			page: location.pathname,
      first_name: this.state.full_name,
      email: this.state.email,
      phone_no: this.state.phone,
      zipcode: this.state.zipcode,
      comment: this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
        agreeViaSms: this.state.agreeViaSms,

    }
	console.log("==========", data)
    axios({
      method: "post",
       url: "https://crm.senseicrm.com/api/create-lead-wordpress",
       data: data,
       headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
     }).then((res) => {
       this.setState({ full_name: '', email: '', phone: '', zipcode: '', comment: '', agreeViaSms: false});
       navigate("/thank-you-get-in-touch");
     }).catch((error) => {
         alert(error)
     });
  }
  render() {
		return(
			<Wrap>
				<div className="container">
					<FormWrap>
						<form id="GetInTouch" onSubmit={this.handleSubmit}>
							<SectionTitle textAlign="left">Get In Touch</SectionTitle>
							<p>We not only fulfill your metal building needs, but we also make dreams come true.</p>
							<div className="two-col">
								<input type="text" name="full_name" id="full_name" onChange={this.handleChange} placeholder="Full Name" pattern="[A-Za-z\s]+$" required />
								<input type="email" name="email" id="email" onChange={this.handleChange} placeholder="Email Id" required />
							</div>
							<div className="two-col">
								<input type="tel" name="phone" id="phone" onChange={this.handleChange} placeholder="Contact Number" pattern="[0-9]{10}" required />
								<input type="number" name="zipcode" id="zipcode" onChange={this.handleChange} placeholder="Zip Code" pattern="[0-9]+$" required />
							</div>
							<textarea name="comment" placeholder="Comment" onChange={this.handleChange} id="comment" rows="4" />
							<div className='one-col'>
							<Checkbox>
								<label>
									<input
									className="form-control"
									type="checkbox"
									id="agreeViaSms"
									name="agreeViaSms"
									defaultChecked={true}
									onChange={this.handleChange}
									aria-label="contacted via SMS"
									/>
									{` `}I agree that I will be contacted via SMS.
								</label>
								</Checkbox>
								<HelpText>By submitting this form, I agree to receive text messages from Buildings And More. Standard messaging rates and data charges may apply. Message frequency may vary. You can opt-out by replying STOP at any time or reply Help to get more information.</HelpText>
							</div>
							<button type="submit">
								<PrimaryLinkButton text="Submit" icon={<LineArrowRight />} />
							</button>
						</form>
					</FormWrap>
					<AddressWrap>
						<div className="logo">
							<StaticImage
								src="../../images/whitelogo.png"
								placeholder="blurred"                          
								alt="white logo"
							/>
						</div>
						<p className="city-name">Lake City</p>
			      <div className="item">
			      	<MapPointerIcon fill="#ffffff" />
			      	792 SW Bascom Norris Drive, Lake City, FL 32025
			      </div>
			      <div className="item">
			      	<PhoneIcon fill="#ffffff" />
			      	<a href="tel:3867556449">
			      	(386) 755-6449
			      	</a>
			      </div>
						<div className="divided-border"></div>
						<p className="city-name">Macclenny</p>
			      <div className="item">
			      	<MapPointerIcon fill="#ffffff" />
			      	1168 Macclenny Avenue West, Macclenny, FL 32063
			      </div>
			      <div className="item">
			      	<PhoneIcon fill="#ffffff" />
			      	<a href="tel:9042594054">
			      	(904) 259-4054
			      	</a>
			      </div>
						<div className="divided-border"></div>
						<p className="city-name">Starke</p>
			      <div className="item">
			      	<MapPointerIcon fill="#ffffff" />
			      	1540 South Walnut Street, Starke, FL 32091
			      </div>
			      <div className="item">
			      	<PhoneIcon fill="#ffffff" />
			      	<a href="tel:9049660077">
							(904) 966-0077
			      	</a>
			      </div>
						
					<div className="item">
						<TollFreeIcon fill="#ffffff" />
						<a href="tel:9042594054">
							Toll Free: (904) 259-4054
						</a>
					</div>
				
					<div className="item">
						<MailIcon fill="#ffffff" />
						<a href="mailto:Info@buildingandmore.com">
							Info@buildingandmore.com
						</a>
					</div>
					</AddressWrap>
				</div>
			</Wrap>
		)
	}
}

export default GetInTouch;
